<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :action-filter="actionFilter"
        :employees-filter="employeesFilter"
        :status-filter="statusFilter"
        :suppliers-filter="suppliersFilter"
        :good-receipts-filter="goodReceiptsFilter"
        :purchase-orders-filter="purchaseOrderFilter"
        @updateActionFilter="updateActionFilter($event)"
        @updateSuppliersFilter="updateSuppliersFilter($event)"
        @updateEmployeesFilter="updateEmployeesFilter($event)"
        @updateStatusFilter="updateStatusFilter($event)"
        @updateGoodsReceiptsFilter="updateGoodsReceiptsFilter($event)"
        @updatePurchaseOrderFilter="updatePurchaseOrderFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        @exportReportFile="exportFile"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
  </div>
</template>

<script>
import { exportExcelFile } from "@/core/composables";
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },
  data() {
    return {
      actionFilter: [],
      cancelStatusFilter: 0,
      currentPage: 1,
      dateFilter: {
        value: null,
        type: 1
      },
      employeesFilter: [],
      suppliersFilter: [],
      goodReceiptsFilter: [],
      purchaseOrderFilter: [],
      itemPerPage: 50,
      branchesFilter: null,
      searchKey: null,
      statusFilter: [],
      typeFilter: null
    };
  },
  watch: {
    "$route.query"(val) {
      this.getListOffBiz(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getListOffBiz(route.query);
  },
  methods: {
    async exportFile() {
      await exportExcelFile(this, {
        storeName: "WAITING_ASSEMBLY_NOTE",
        fileName: "phieu_may_cho_lap_rap_",
        payload: {
          filter: {
            assigned_to_id: this.employeesFilter,
            step: this.statusFilter,
            supplier: this.suppliersFilter,
            po: this.purchaseOrderFilter,
            good_receipts: this.goodReceiptsFilter,
            selected_action: this.actionFilter
          },
          model_name: "App\\Models\\LaptopToBeAssembled"
        }
      });
    },

    async pushRouter() {
      await this.$router.push({
        name: "not-sold-warehouse_waiting-assembly-notes",
        query: {
          selected_action:
            this.actionFilter && this.actionFilter !== "all"
              ? this.actionFilter
              : undefined,
          status:
            this.statusFilter && this.statusFilter !== "all"
              ? this.statusFilter
              : undefined,
          assigned_to_id:
            this.employeesFilter && this.employeesFilter.length > 0
              ? this.employeesFilter
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          supplier:
            this.suppliersFilter.length > 0 ? this.suppliersFilter : undefined,
          goodReceiptsFilter:
            this.goodReceiptsFilter.length > 0
              ? this.goodReceiptsFilter
              : undefined,
          purchaseOrderFilter:
            this.purchaseOrderFilter.length > 0
              ? this.purchaseOrderFilter
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getListOffBiz(query) {
      if (query.page) {
        // Set current page
        this.currentPage = parseInt(query.page);
        //set filtered supplier
        this.suppliersFilter =
          typeof query.supplier === "string"
            ? [parseInt(query.supplier)]
            : typeof query.supplier === "object"
            ? query.supplier.map(item => parseInt(item))
            : [];
        // Set filtered employees
        this.employeesFilter =
          typeof query.assigned_to_id === "string"
            ? [parseInt(query.assigned_to_id)]
            : typeof query.assigned_to_id === "object"
            ? query.assigned_to_id.map(item => parseInt(item))
            : [];

        // Set filtered goodReceiptsFilter
        this.goodReceiptsFilter =
          typeof query.goodReceiptsFilter === "string"
            ? [parseInt(query.goodReceiptsFilter)]
            : typeof query.goodReceiptsFilter === "object"
            ? query.goodReceiptsFilter.map(item => parseInt(item))
            : [];
        // Set filtered purchaseOrderFilter
        this.purchaseOrderFilter =
          typeof query.purchaseOrderFilter === "string"
            ? [parseInt(query.purchaseOrderFilter)]
            : typeof query.purchaseOrderFilter === "object"
            ? query.purchaseOrderFilter.map(item => parseInt(item))
            : [];
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set search key
        this.searchKey = query.search || null;
        // Set filtered status
        this.statusFilter =
          typeof query.status === "string"
            ? [parseInt(query.status)]
            : typeof query.status === "object"
            ? query.status.map(item => parseInt(item))
            : [];

        // Set action filter
        this.actionFilter =
          typeof query.selected_action === "string"
            ? [parseInt(query.selected_action)]
            : typeof query.selected_action === "object"
            ? query.selected_action.map(item => parseInt(item))
            : [];

        // Get guarantee vouchers
        await this.$store.dispatch("WAITING_ASSEMBLY_NOTE/getListOffBiz", {
          filter: {
            assigned_to_id: this.employeesFilter,
            selected_action: this.actionFilter,
            step: this.statusFilter,
            supplier: this.suppliersFilter,
            po: this.purchaseOrderFilter,
            good_receipts: this.goodReceiptsFilter
          },
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateActionFilter(val) {
      this.actionFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateEmployeesFilter(val) {
      this.employeesFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateSuppliersFilter(val) {
      this.suppliersFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateGoodsReceiptsFilter(val) {
      this.goodReceiptsFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updatePurchaseOrderFilter(val) {
      this.purchaseOrderFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },
    //updatePurchaseOrderFilter

    updateStatusFilter(val) {
      this.statusFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
