<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Phiếu máy chờ lắp ráp</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div class="">
        <div class="font-weight-bold mb-2">Trạng thái</div>
        <v-checkbox
          v-model="selectedStatus"
          class="mt-1"
          dense
          hide-details
          v-for="item in statusList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></v-checkbox>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Hành động</div>
        <v-checkbox
          v-model="selectedAction"
          v-for="item in ACTION_STATUS"
          :label="item.label"
          :key="item.value"
          :value="item.value"
          class="mt-1"
          dense
          hide-details
        ></v-checkbox>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Nhân viên xử lý</div>
        <input-autocomplete
          :items="employees"
          v-model="employeesFilter"
          item-text="name"
          item-value="id"
          multiple
          placeholder="Chọn nhân viên"
          :updateValue="updateEmployees"
        ></input-autocomplete>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">P/O</div>
        <input-autocomplete
          :items="purchaseOrders"
          v-model="purchaseOrdersFilter"
          item-text="code"
          item-value="id"
          multiple
          placeholder="Tìm P/O"
          :updateValue="updatePurchaseOrder"
        ></input-autocomplete>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Phiếu nhập</div>
        <input-autocomplete
          :items="goodReceipts"
          v-model="goodReceiptsFilter"
          item-text="code"
          item-value="id"
          multiple
          placeholder="Tìm phiếu nhập"
          :updateValue="updateGoodsReceipts"
        ></input-autocomplete>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Nhà cung cấp</div>
        <input-autocomplete
          v-model="suppliersFilter"
          :items="suppliers"
          item-text="name"
          item-value="id"
          multiple
          placeholder="Chọn nhà cung cấp"
          :updateValue="updateSupplier"
        ></input-autocomplete>
      </div>
    </v-card>
  </div>
</template>

<script>
import InputAutocomplete from "../../../../components/common/InputAutocomplete";
import { ACTION_STATUS } from "@/modules/NotSoldWarehouse/utils/static-data";

export default {
  components: {
    InputAutocomplete
  },
  props: {
    actionFilter: {
      type: Array
    },
    employeesFilter: {
      type: Array
    },
    suppliersFilter: {
      type: Array
    },
    statusFilter: {
      type: Array
    },
    goodReceiptsFilter: {
      type: Array
    },
    purchaseOrdersFilter: {
      type: Array
    }
  },
  data() {
    return {
      ACTION_STATUS: ACTION_STATUS,
      statusList: [
        { label: "Đã tiếp nhận", value: 1 },
        { label: "Chờ linh kiện", value: 2 },
        { label: "Đang xử lý", value: 3 },
        { label: "Đã xử lý xong (chờ kiểm tra)", value: 4 }
      ]
    };
  },
  computed: {
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },

    goodReceipts() {
      return this.$store.getters["INTERNAL_REPAIR_NOTE/goodsReceipts"];
    },

    selectedAction: {
      get() {
        return this.actionFilter;
      },
      set(val) {
        this.$emit("updateActionFilter", val);
      }
    },

    selectedStatus: {
      get() {
        return this.statusFilter;
      },
      set(val) {
        this.$emit("updateStatusFilter", val);
      }
    },

    suppliers() {
      return this.$store.getters["SUPPLIER/allSuppliers"];
    },

    purchaseOrders() {
      return this.$store.getters["INTERNAL_REPAIR_NOTE/purchaseOrder"];
    }
  },
  created() {
    this.$store.dispatch("EMPLOYEE/getAllEmployees", "ENGINEERING");
    this.$store.dispatch("SUPPLIER/getAllSuppliers", "?sup_cate_ids=1");
    this.$store.dispatch("INTERNAL_REPAIR_NOTE/getGoodsReceipts");
    this.$store.dispatch("INTERNAL_REPAIR_NOTE/getPurchaseOrders");
    //getGoodsReceipts
  },
  methods: {
    updateEmployees(val) {
      this.$emit("updateEmployeesFilter", val);
    },
    updateSupplier(val) {
      this.$emit("updateSuppliersFilter", val);
    },
    updateGoodsReceipts(val) {
      this.$emit("updateGoodsReceiptsFilter", val);
    },
    updatePurchaseOrder(val) {
      this.$emit("updatePurchaseOrderFilter", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
